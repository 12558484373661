import React from 'react';
import '../styles/Portfolio.scss';
import PortfolioTags from './PortfolioTags';
import PortfolioElement from './PortfolioElement';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySlides: props.pics.slides,
    };
    this.id = props.id;
    this.pics = props.pics;

  }
  
  selectCategory = (category) => {
    const newTagSlidesSelected = this.pics.slides.filter(e => e.tag === category)
    this.setState({
      displaySlides: newTagSlidesSelected
    })
  }

  render() {
    return (
      <div
        id={this.id}
        className="carousel carousel-dark slide columnPortfolio"
        data-bs-ride="carousel"
      >
        <PortfolioTags 
          slides={this.pics.slides} 
          selectCategory={this.selectCategory}
        />
        <div className="item__wrapper">
          {
            this.state.displaySlides.map((slide, ind) => (
              <PortfolioElement 
                id={`element-portfolio-${ind}`} 
                slide={slide} 
                path={this.pics.path} 
                ext={this.pics.ext} 
              />
            ))
          }
        </div>
      </div>
    );
  }
}

export default Portfolio;
