import React from 'react';
import '../styles/Portfolio.scss';

const PortfolioElement = (props) => {
  return (
    <img loading="lazy"
    src={props.path + props.slide.file + props.ext}
    className="itemPortfolio"
    alt={props.slide.tag}
    />
  );
};

export default PortfolioElement;