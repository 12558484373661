import React, {useState} from 'react';
import '../styles/Portfolio.scss';

const PortfolioTags = (props) => {
  let slidesSet = new Set();
  const [activeId, setActiveId] = useState();
  props.slides.map(e => slidesSet.add(e.tag));
  return (
      <div className="portfolio-tags">
        {
          Array.from(slidesSet).map((slide, index) => (
            <button
              key={slide}
              onClick={() => {
                setActiveId(index)
                return  props.selectCategory(slide)
              }}
              type="button"
              data-bs-target={`#portfolioTags${index}`}
              data-bs-slide-to={index}
              className={`tegButton ${activeId  === index? 'active' : ''}`}
              aria-current={!index}
              aria-label={`Slide ${index + 1}`}
            >
              {slide}
            </button>
          ))
        }
      </div>
  );
};

export default PortfolioTags;
