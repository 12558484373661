import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar, faCamera, faTruck } from '@fortawesome/free-solid-svg-icons'


const Sale = () => (
  <main className='landing-page' size="6x">
    
    <div id='solves' className="container col-xxl-10 py-5 solves">
    <FontAwesomeIcon icon={faHandHoldingDollar} size="6x" className='saleIcon'/>
      <h2 className="display-5 fw-bold text-center">Cкидка на серийную 3D-печати</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          При большом колличестве заказов на одинаковую или однотипную печать цены уменьшаются. (обсуждается индивидуально)
        </p>
      </div>
    </div>

    <div id='solves' className="container col-xxl-10 py-5 solves">
      <FontAwesomeIcon icon={faTruck} size="6x" className='saleIcon'/>
      <h2 className="display-5 fw-bold text-center">При заказе от 200р доставка по Минску бесплатно</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          Доставка по Минску. Привозим прямо домой. 
        </p>
      </div>
    </div>

    <div id='solves' className="container col-xxl-10 py-5 solves">
      <FontAwesomeIcon icon={faCamera} size="6x" className='saleIcon'/>
      <h2 className="display-5 fw-bold text-center">3D сканирование бесплатно при печати от 250р</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          3D сканирование, это отдельный элемент качественной печати. Мы сделаем его бесплатно для вас.
        </p>
      </div>
    </div>

  </main>
);

export default Sale;
