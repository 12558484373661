import React from "react";
import "../styles/Landing.scss";
import Gallery from "react-grid-gallery";

const IMAGES = [
  {
    src: "/img/gallery/3d_detal.jpg",
    thumbnail: "/img/gallery/3d_detal.jpg",
    tags: [
      { value: "крышка", title: "крышка" },
      { value: "volkswagen", title: "volkswagen" },
    ],
    caption: "крышка шторки volkswagen Passat b5",
  },
  {
    src: "/img/gallery/3d_detal_02.jpg",
    thumbnail: "/img/gallery/3d_detal_02.jpg",
    tags: [
      { value: "заглушка", title: "заглушка" },
      { value: "bmw", title: "bmw" },
    ],
    caption: "заглушка литого диска bmw",
  },
  {
    src: "/img/gallery/3d_detal_03.jpg",
    thumbnail: "/img/gallery/3d_detal_03.jpg",
    tags: [
      { value: "воздухозаборник", title: "воздухозаборник" },
      { value: "квадроцикл", title: "квадроцикл" },
    ],
    caption: "воздухозаборник двигателя квадроцикла",
  },
  {
    src: "/img/gallery/3d_detal_04.jpg",
    thumbnail: "/img/gallery/3d_detal_04.jpg",
    tags: [
      { value: "кронштейн", title: "кронштейн" },
      { value: "tesla", title: "tesla" },
    ],
    caption: "кронштейн компрессора пневмоподвески tesla",
  },
  {
    src: "/img/gallery/3d_detal_05.jpg",
    thumbnail: "/img/gallery/3d_detal_05.jpg",
    tags: [
      { value: "шестерня", title: "шестерня" },
      { value: "червяк", title: "червяк" },
    ],
    caption: "червячная пара",
  },
  {
    src: "/img/gallery/3d_detal_06.jpg",
    thumbnail: "/img/gallery/3d_detal_06.jpg",
    tags: [
      { value: "крышка", title: "крышка" },
      { value: "пила", title: "пила" },
    ],
    caption: "крышка корпуса бензопилы",
  },
  {
    src: "/img/gallery/3d_detal_07.jpg",
    thumbnail: "/img/gallery/3d_detal_07.jpg",
    tags: [
      { value: "шестерня", title: "шестерня" },
      { value: "косозубая", title: "косозуубая" },
    ],
    caption: "ремонт червячной пары",
  },
  {
    src: "/img/gallery/3d_detal_08.jpg",
    thumbnail: "/img/gallery/3d_detal_08.jpg",
    tags: [
      { value: "крышка", title: "крышка" },
      { value: "корпус", title: "корпус" },
    ],
    caption: "крышка корпуса бензопилы",
  },
  {
    src: "/img/gallery/3d_detal_09.jpg",
    thumbnail: "/img/gallery/3d_detal_09.jpg",
    tags: [
      { value: "бензопила", title: "бензопила" },
      { value: "пила", title: "пила" },
    ],
    caption: "ремонт пластиковой крышки корпуса бензопилы",
  },
  {
    src: "/img/gallery/3d_detal_10.jpg",
    thumbnail: "/img/gallery/3d_detal_10.jpg",
    tags: [
      { value: "кронштейн", title: "кронштейн" },
      { value: "бампер", title: "бампер" },
    ],
    caption: "кронштейн крепления бампера jaguar",
  },
  {
    src: "/img/gallery/3d_detal_11.jpg",
    thumbnail: "/img/gallery/3d_detal_11.jpg",
    tags: [
      { value: "колпак", title: "колпак" },
      { value: "volkswagen", title: "volkswagen" },
    ],
    caption: "заглушка на литой диск volkswagen golf 2",
  },
  {
    src: "/img/gallery/3d_detal_12.jpg",
    thumbnail: "/img/gallery/3d_detal_12.jpg",
    tags: [
      { value: "климатконтроль", title: "климатконтроль" },
      { value: "sitroen", title: "sitroen" },
    ],
    caption: "ремонт привода климатконтроля sitroen",
  },
  {
    src: "/img/gallery/3d_detal_13.jpg",
    thumbnail: "/img/gallery/3d_detal_13.jpg",
    tags: [
      { value: "колпак", title: "колпак" },
      { value: "колесо", title: "колесо" },
    ],
    caption: "декоративный колпак на литой диск",
  },
  {
    src: "/img/gallery/3d_detal_14.jpg",
    thumbnail: "/img/gallery/3d_detal_14.jpg",
    tags: [
      { value: "переходник", title: "переходник" },
      { value: "volkswagen", title: "volkswagen" },
    ],
    caption: "приборная доска volkswagen golf 2",
  },
  {
    src: "/img/gallery/3d_detal_15.jpg",
    thumbnail: "/img/gallery/3d_detal_15.jpg",
    tags: [
      { value: "вольтметр", title: "вольтметр" },
      { value: "приборка", title: "приборка" },
    ],
    caption: "приборная доска volkswagen golf 2",
  },
  {
    src: "/img/gallery/3d_detal_17.jpg",
    thumbnail: "/img/gallery/3d_detal_17.jpg",
    tags: [
      { value: "шестерня", title: "шестерня" },
      { value: "замок", title: "замок" },
    ],
    caption: "шестерня замка багажника volkswagen polo sedan",
  },
  {
    src: "/img/gallery/3d_detal_18.jpg",
    thumbnail: "/img/gallery/3d_detal_18.jpg",

    tags: [
      { value: "усилитель", title: "усилитель" },
      { value: "автозвук", title: "автозвук" },
    ],
    caption: "ремонт корпуса автоусилителя",
  },
  {
    src: "/img/gallery/3d_detal_19.jpg",
    thumbnail: "/img/gallery/3d_detal_19.jpg",
    tags: [
      { value: "переходник", title: "переходник" },
      { value: "modelx", title: "modelx" },
    ],
    caption: "переходник зарядки tesla model x",
  },
  {
    src: "/img/gallery/3d_detal_20.jpg",
    thumbnail: "/img/gallery/3d_detal_20.jpg",
    tags: [
      { value: "зарядное", title: "зарядное" },
      { value: "штекер", title: "штекер" },
    ],
    caption: "корпус переходника зарядки tesla model x",
  },
  {
    src: "/img/gallery/3d_detal_21.jpg",
    thumbnail: "/img/gallery/3d_detal_21.jpg",
    tags: [
      { value: "вилка", title: "вилка" },
      { value: "штекер", title: "штекер" },
    ],
    caption: "корпус переходника зарядки tesla model x",
  },
  {
    src: "/img/gallery/3d_detal_22.jpg",
    thumbnail: "/img/gallery/3d_detal_22.jpg",
    tags: [
      { value: "modelx", title: "modelx" },
      { value: "тесла", title: "тесла" },
    ],
    caption: "корпус переходника зарядки tesla model x",
  },
  {
    src: "/img/gallery/3d_detal_23.jpg",
    thumbnail: "/img/gallery/3d_detal_23.jpg",
    tags: [
      { value: "3d", title: "3d" },
      { value: "fdm", title: "fdm" },
    ],
    caption: "сканирование и печать корпуса переходника зарядки tesla model x",
  },
  {
    src: "/img/gallery/3d_detal_24.jpg",
    thumbnail: "/img/gallery/3d_detal_24.jpg",
    tags: [
      { value: "дефлектор", title: "дефлектор" },
      { value: "polosedan", title: "polosedan" },
    ],
    caption: "ручка дефлектора volkswagen polo sedan",
  },
  {
    src: "/img/gallery/3d_detal_25.jpg",
    thumbnail: "/img/gallery/3d_detal_25.jpg",
    tags: [
      { value: "зарядное", title: "зарядное" },
      { value: "tesla", title: "tesla" },
    ],
    caption: "3d печать переходника зарядного устройства",
  },
  {
    src: "/img/gallery/3d_detal_26.jpg",
    thumbnail: "/img/gallery/3d_detal_26.jpg",
    tags: [
      { value: "вставка", title: "вставка" },
      { value: "противотуманка", title: "противотуманка" },
    ],
    caption: "решетка в отверстие противотуманной фары",
  },
  {
    src: "/img/gallery/3d_detal_27.jpg",
    thumbnail: "/img/gallery/3d_detal_27.jpg",
    tags: [
      { value: "динамик", title: "динамик" },
      { value: "решетка", title: "решетка" },
    ],
    caption: "решетка динамика",
  },
  {
    src: "/img/gallery/3d_detal_28.jpg",
    thumbnail: "/img/gallery/3d_detal_28.jpg",
    tags: [
      { value: "шестерня", title: "шестерня" },
      { value: "стеклоподьемник", title: "стеклоподьемник" },
    ],
    caption: "шестерня стеклоподъемника Chevrolet Niva",
  },
  {
    src: "/img/gallery/3d_detal_29.jpg",
    thumbnail: "/img/gallery/3d_detal_29.jpg",
    tags: [
      { value: "audi100", title: "audi100" },
      { value: "одометр", title: "одометр" },
    ],
    caption: "шестерня одометра audi",
  },
  {
    src: "/img/gallery/3d_detal_30.jpg",
    thumbnail: "/img/gallery/3d_detal_30.jpg",
    tags: [
      { value: "копия", title: "копия" },
      { value: "3dпечать", title: "3dпечать" },
    ],
    caption: "шестерня",
  },
  {
    src: "/img/gallery/3d_detal_31.jpg",
    thumbnail: "/img/gallery/3d_detal_31.jpg",

    tags: [
      { value: "вставка", title: "вставка" },
      { value: "фара", title: "фара" },
    ],
    caption: "вставка в нишу противотуманки",
  },
  {
    src: "/img/gallery/3d_detal_32.jpg",
    thumbnail: "/img/gallery/3d_detal_31.jpg",

    tags: [
      { value: "вставка", title: "вставка" },
      { value: "фара", title: "фара" },
    ],
    caption: "вставка в нишу противотуманки",
  },
  {
    src: "/img/gallery/3d_detal_33.jpg",
    thumbnail: "/img/gallery/3d_detal_33.jpg",

    tags: [
      { value: "кондиционер", title: "кондиционер" },
      { value: "климатконтроль", title: "климатконтроль" },
    ],
    caption: "polo sedan",
  },
  {
    src: "/img/gallery/3d_detal_34.jpg",
    thumbnail: "/img/gallery/3d_detal_34.jpg",
    tags: [
      { value: "роботпылесос", title: "роботпылесос" },
      { value: "шестеренка", title: "шестеренка" },
    ],
    caption: "шестерня робота пылесоса",
  },
  {
    src: "/img/gallery/3d_detal_35.jpg",
    thumbnail: "/img/gallery/3d_detal_35.jpg",
    tags: [
      { value: "sokdafabia", title: "skodafabia" },
      { value: "шестеренка", title: "шестеренка" },
    ],
    caption: "шестерня замка багажника skodafabia",
  },
  {
    src: "/img/gallery/3d_detal_36.jpg",
    thumbnail: "/img/gallery/3d_detal_36.jpg",
    tags: [
      { value: "rangerover", title: "rangerover" },
      { value: "рамка", title: "рамка" },
    ],
    caption: "рамка люка rangerover",
  },
  {
    src: "/img/gallery/3d_detal_37.jpg",
    thumbnail: "/img/gallery/3d_detal_37.jpg",
    tags: [
      { value: "печатьполиуретаном", title: "печатьполиуретаном" },
      { value: "teslamodelx", title: "teslamodelx" },
    ],
    caption: "крепление радиатора tesla model x",
  },
  {
    src: "/img/gallery/3d_detal_38.jpg",
    thumbnail: "/img/gallery/3d_detal_38.jpg",

    tags: [
      { value: "печатьпластикомabs", title: "печатьпластикомabs" },
      { value: "3dпечать", title: "3dпечать" },
    ],
    caption: "крепление радиатора tesla model x",
  },
  {
    src: "/img/gallery/3d_detal_39.jpg",
    thumbnail: "/img/gallery/3d_detal_39.jpg",
    tags: [
      { value: "печатьпластикомabs", title: "печатьпластикомabs" },
      { value: "3dпечатьминск", title: "3dпечатьминск" },
    ],
    caption: "круногабаратная 3d печать",
  },
  {
    src: "/img/gallery/3d_detal_40.jpg",
    thumbnail: "/img/gallery/3d_detal_40.jpg",
    tags: [
      { value: "audiq7", title: "audiq7" },
      { value: "sideassist", title: "sideassist" },
    ],
    caption: "кронштейн sideassist audi q7",
  },
  {
    src: "/img/gallery/3d_detal_41.jpg",
    thumbnail: "/img/gallery/3d_detal_41.jpg",
    tags: [
      { value: "audiq7", title: "audiq7" },
      { value: "sideassist", title: "sideassist" },
    ],
    caption: "кронштейн sideassist audi q7",
  },
  {
    src: "/img/gallery/3d_detal_42.jpg",
    thumbnail: "/img/gallery/3d_detal_42.jpg",
    tags: [
      { value: "audiq7", title: "audiq7" },
      { value: "sideassist", title: "sideassist" },
    ],
    caption: "кронштейн sideassist audi q7",
  },
];

const GalleryPage = () => (
<div  className="container col-xxl-10 py-5 about">
  <div style={{
                    display: "block",
                    minHeight: "1px",
                    width: "100%",
                    border: "1px solid #ddd",
                    overflow: "auto"}}>
<Gallery

images={IMAGES}
lightboxWidth={1536}
/>
</div>
</div>
  
//   <main className="landing-page">
//  <div style={{display: "block", minHeight: "1px", width: "100%", border: "1px solid rgb(221, 221, 221)", overflow: "auto"}}>
//         <h2 className="display-5 fw-bold text-center">
//           Галерея 3D печати
//         </h2>
//       <Gallery images={IMAGES} />
//       </div>

//     <div id="solves" className="container col-xxl-10 py-5 solves ">
     
//       <div className="col-lg-6 mx-auto text-center">
//         <p className="lead my-10">
//           При большом колличестве заказов на одинаковую или однотипную печать
//           цены уменьшаются. (обсуждается индивидуально)
//         </p>
//       </div>
//     </div>
//   </main>
);

export default GalleryPage;
