import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

const Resources = () => (
  <main className='landing-page'>

<div id='interesting-models' className="container col-xxl-10 py-5 interesting-models">
      <svg focusable="false" role="img"
           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={144} height={114}
           className="d-block mx-auto mb-4">
        <path fill='#FF5A3C'
              d="M384 352c-6.3 0-12.26 1.19-18.01 2.89l-32.42-54.02C344.95 289.31 352 273.49 352 256s-7.05-33.31-18.42-44.87L366 157.11c5.75 1.7 11.71 2.89 18.01 2.89 35.35 0 64-28.65 64-64s-28.65-64-64-64c-29.79 0-54.6 20.45-61.74 48H125.74C118.6 52.45 93.79 32 64 32 28.65 32 0 60.65 0 96c0 29.79 20.44 54.6 48 61.74v196.53C20.44 361.4 0 386.21 0 416c0 35.35 28.65 64 64 64 29.79 0 54.6-20.44 61.74-48h196.53c7.14 27.56 31.95 48 61.74 48 35.35 0 64-28.65 64-64-.01-35.35-28.66-64-64.01-64zm-304 2.26V157.74c22.41-5.8 39.93-23.32 45.74-45.74h196.53c2.86 11.04 8.4 20.99 16.16 28.87l-32.42 54.02c-5.75-1.7-11.71-2.89-18.01-2.89-35.35 0-64 28.65-64 64s28.65 64 64 64c6.3 0 12.26-1.19 18.01-2.89l32.42 54.02c-7.76 7.88-13.3 17.83-16.16 28.87H125.74A63.814 63.814 0 0 0 80 354.26zM288 288c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32zm96-224c17.64 0 32 14.36 32 32s-14.36 32-32 32-32-14.36-32-32 14.36-32 32-32zM32 96c0-17.64 14.36-32 32-32s32 14.36 32 32-14.36 32-32 32-32-14.36-32-32zm32 352c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32zm320 0c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32z"/>
      </svg>
      <div className="row">
        <div className="col-sm-12">
          <h2 className="display-5 fw-bold text-center mb-4">Ресурсы с моделями для 3D-печати</h2>
          <p className='fs-7 text-muted text-center'>
            Хотите что-нибудь распечатать, но не знаете с чего начать?
            <br/>
            Найдите вдохновение среди тысяч моделей от любителей до профессионалов 3D-моделирования.
          </p>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-lg-3 ">
        <div className="col">
          <div className="p-3">
            <a href="https://www.thingiverse.com" target="_blank" rel="noreferrer">
              <img loading="lazy"
                   src="/img/Landing/interesting-models/thingivers.webp"
                   className="img-fluid"
                   alt="Thingivers"
              />
            </a>
            <h5 className="mg-md">Thingivers</h5>
            <p>
              Сайт Thingiverse принадлежит компании MakerBot Industries, создателю
              популярной серии 3D-принтеров Replicator. Сайт позволяет
              пользователями хранить и обмениваться файлами 3D-моделей, которые
              используются с 3D-принтерами. Это очень популярный сайт, и большое
              сообщество людей хранит на нем файлы различных категорий. Так что,
              если вы ищите "крутые" вещи для печати, то стоит посетить Thingiverse.
            </p>
            <a
              href="https://www.thingiverse.com"
              className="btn btn-outline-red-orange"
              target="_blank" rel="noreferrer"
            >
              Перейти на сайт
            </a>
          </div>
        </div>
        <div className="col">
          <div className="p-3">
            <a href="https://www.yeggi.com" target="_blank" rel="noreferrer">
              <img loading="lazy" src="/img/Landing/interesting-models/yeggi.webp" className="img-fluid" alt="Yeggi"/>
            </a>
            <h5 className="mg-md">Yeggi</h5>
            <p>
              Yeggi является поисковиком, который будет просматривать основные сайты
              трехмерной печати для нахождения файлов, совместимых с 3D-принтерами.
              Вы также можете просмотреть некоторые популярные поиски, чтобы
              получить представление о том, чем сейчас интересуется сообщество.
            </p>
            <a
              href="https://www.yeggi.com"
              className="btn btn-outline-red-orange"
              target="_blank" rel="noreferrer"
            >
              Перейти на сайт
            </a>
          </div>
        </div>
        <div className="col">
          <div className="p-3">
            <a href="https://www.myminifactory.com" target="_blank" rel="noreferrer">
              <img loading="lazy"
                   src="/img/Landing/interesting-models/myminifactory.webp"
                   className="img-fluid"
                   alt="MyMiniFactory"
              />
            </a>
            <h5 className="mg-md">MyMiniFactory</h5>
            <p>
              Сайт My Mini Factory представляет собой депозитарий 3D-моделей,
              которым управляет онлайновый магазин iMakr, продающий 3D-принтеры и
              принадлежности к ним. Он также имеет один из самых больших магазинов
              3D-принтеров в Центральном Лондоне. Сайт содержит 3D-модели,
              разработанные профессионалами, и все хранимые на нем модели проверены
              на качество. Вы также можете сделать запрос на нужную модель, которую
              их дизайнеры создадут, и выложат в открытый доступ.
            </p>
            <a
              href="https://www.myminifactory.com"
              className="btn btn-outline-red-orange"
              target="_blank" rel="noreferrer"
            >
              Перейти на сайт
            </a>
          </div>
        </div>
        <div className="col">
          <div className="p-3">
            <a href="https://pinshape.com">
              <img loading="lazy" src="/img/Landing/interesting-models/pinshape.webp" className="img-fluid"
                   alt="Pinshape"/>
            </a>
            <h5 className="mg-md">Pinshape</h5>
            <p>
              Сайт Pinshape, рынок 3D-моделей, все еще находится на стадии
              бета-тестирования. Он по стилю (раскладке, внешнему виду и способности
              фиксировать понравившееся) похож на известный сайт Pinterest. Вы
              можете покупать модели у дизайнеров, либо скачивать их для
              самостоятельной печати, если они предлагаются бесплатно.
            </p>
            <a
              href="https://pinshape.com"
              className="btn btn-outline-red-orange"
              target="_blank" rel="noreferrer"
            >
              Перейти на сайт
            </a>
          </div>
        </div>
        <div className="col">
          <div className="p-3">
            <a href="https://cults3d.com/en" target="_blank" rel="noreferrer">
              <img loading="lazy" src="/img/Landing/interesting-models/cults.webp" className="img-fluid" alt="Cults"/>
            </a>
            <h5 className="mg-md">Cults</h5>
            <p>
              Французское сообщество и рынок, где разработчики могут обмениваться
              или продавать свои разработки. Сайт имеет коллекцию высококачественных
              моделей, которые можно скачивать. Пользователи могут следить за
              работами нравящихся им дизайнеров, и получать оперативные обновления,
              когда на сайт выкладывается новое творение. Имя сайта, Cults,
              представляет собой написанное в обратном направлении Святого Луки (St.
              Luc), покровителя художников. Сайт также поддерживает английский язык.
            </p>
            <a
              href="https://cults3d.com/en"
              className="btn btn-outline-red-orange"
              target="_blank" rel="noreferrer"
            >
              Перейти на сайт
            </a>
          </div>
        </div>
        <div className="col">
          <div className="p-3">
            <a href="http://3dtoday.ru" target="_blank" rel="noreferrer">
              <img loading="lazy"
                   src="/img/Landing/interesting-models/3dtoday.webp"
                   className="img-fluid"
                   alt="ac_3dtoday"
              />
            </a>
            <h5 className="mg-md">
              3Dtoyday <br/>
            </h5>
            <p>
              3Dtoday – портал для любителей и профессионалов, заинтересованных в
              3D-печати и сопутствующих технологиях. Наша миссия – предоставить
              исчерпывающую информацию о технологиях аддитивного производства и дать
              нашим посетителям возможность поделиться знаниями с единомышленниками.
              Россия
            </p>
            <a
              href="http://3dtoday.ru"
              className="btn btn-outline-red-orange"
              target="_blank" rel="noreferrer"
            >
              Перейти на сайт
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id='solves' className="container col-xxl-10 py-5 solves">
      <FontAwesomeIcon icon={faCamera} size="6x" className='saleIcon' />
      <h2 className="display-5 fw-bold text-center">3D сканирование бесплатно при печати от 250р</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          3D сканирование, это отдельный элемент качественной печати. Мы сделаем его бесплатно для вас.
        </p>
      </div>
    </div>

  </main>
);

export default Resources;
