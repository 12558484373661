import React from 'react';
import HeaderBar from './HeaderBar';
import Footer from './Footer';
import ButtonUp from './ButtonUp';

const Layout = ({ children }) => (
  <>
    <HeaderBar/>

    {children}

    <Footer/>

    <ButtonUp/>
  </>
);

export default Layout;
