import React from 'react';
import { INST_PROF_NAME, INSTAGRAM } from '../Data/CONST';

const Footer = () => (
  <footer className='page-footer'>
    <div className="b-example-divider"/>
    <div className='bg-secondary text-white'>
      <div className="container">
        <footer className="py-5">
          <div className="row">
            <div className="col-md-4 mb-5 mb-md-0">
              <h4>Карта сайта</h4>
              <ul className="nav  flex-column">
                <li className="nav-item mb-2">
                  <a href="#about" className="nav-link p-0 link-light">
                    Главная
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a className="nav-link p-0 link-light" href="#how-i-working">
                    Порядок работы
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a className="nav-link p-0 link-light" href="#pricing">
                    Цены
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a className="nav-link p-0 link-light" href="#contacts">
                    Контакты
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a className="nav-link p-0 link-light" href="#interesting-models">
                    Модели 3D
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a className="nav-link p-0 link-light" href="#examples">
                    Примеры работ
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-8">
              <form>
                <h4><span className='fw-lighter'>Подписывайтесь на мой профиль в </span>Instagram</h4>
                <div className="d-flex w-100 gap-2">
                  <a href={INSTAGRAM} className='text-decoration-none text-reset' target='_blank' rel='noreferrer'
                     title='Открыть профиль в новой вкладке'>
                    <svg
                      focusable="false"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className='me-2 is-inst-icon'
                      width='3rem'

                    >
                      <path
                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                      />
                    </svg>
                    <span className='lead mb-0'>{INST_PROF_NAME}</span>
                  </a>
                </div>
                <p>Ежедневный дайджест всего нового и интересного из мира 3D.</p>
              </form>
            </div>
          </div>
          <div className="pt-4 mt-4 border-top text-center">
            <img src="./logo-3d-master-web.png" alt="logo" width='64px'/>
            <p className=''>© 2021 - 2022 3DMasterHand.by</p>
            <p>Высококачественная печать из пластмасс и резиноподобных материалов</p>
          </div>
          <small>
            <a
              href='https://www.instagram.com/_vladimir.osipovich_/'
              className='link-light small'
              target="_blank"
              rel="noreferrer"

            >
              Разработка сайта
            </a>
          </small>

        </footer>
      </div>
    </div>
  </footer>
);

export default Footer;
