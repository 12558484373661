import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

const Scanner = () => (
  <main className='landing-page'>

    <div id='about' className="container col-xxl-10 py-5 about">
      <div className="row flex-lg-row-reverse align-items-center ">
        <div className="col-lg-6">
          <img src="/img/Landing/flying-bear-ghost-5.webp" className="d-block mx-lg-auto img-fluid"
            alt="my 3d printer" loading="lazy"
          />
        </div>
        <div className="col-lg-6">
          <h2 className="display-4 fw-bold lh-1 mb-3">
            3D сканирование и моделирование
          </h2>
          <a href='#contacts' role='button' className="btn btn-lg px-4 me-md-2 w-100 btn-red-orange">
            Заказать 3D-печать
          </a>
        </div>
      </div>
      <p className="lead">
        3d печать неразрывно связана с 3d моделированием, а так же 3d сканированием. Результат 3d печати зависит не только от правильного подбора материалов, правильных настроек, но и от правильно спроектированной 3d модели. При проектировании нужно использовать как можно меньше нависающих поверхностей, а если их не избежать, то размещать их в наиболее удачном положении или делать под максимально тупым углом, так как принтер не способен хорошо печатать «в воздухе».
      </p>
      <span className="badge rounded-pill">Пример:</span>

      <p className='lead'>
        Некоторые детали очень сложно повторить в 3d моделировании обычным способом «померил – смоделировал», в этом случае на помощь приходит 3d сканирование и реверсинжиниринг, а так же фотограметрия.
      </p>
      <h3 className='display-4 fw-bold lh-1 mb-3'>В этой статье мы поговорим об оптических 3d сканерах. <span class="badge bg-secondary">New</span></h3>
      <p className='lead'>
        Оптический 3d сканер –   устройство, анализирующее форму предмета и на основе полученных данных создающее его 3D-модель.
        Принцип работы оптического 3d сканера заключается в захвате изображения как минимум парой камер с разных ракурсов и совмещения полученных отсканированных участков для получения единой 3d модели.
      </p>
      <p className='lead'>
      Оптические сканеры бывают ручные и стационарные. Для получения точных, детализированных, технических моделей используются стационарные, а для художественных целей подойдут и ручные. Степень точности, стационарного оптического сканера может доходить до 0,03 мм, но и цена таких сканеров 50 000 - 100 000 долларов. Так как точность по оси YX (по оси Z до 0.02) подавляющего большинства принтеров FDM печати на данный момент +-0.1 мм, нет большого смысла в применении столь не дешевого оборудования, достаточно моделей попроще, но и они на данный момент довольно дорогие, не стоит рассматривать что-то дешевле 2000 долларов для сканирования технических моделей. В свою очередь для художественных, менее детализированных моделей подойдут и недорогие ручные сканеры, но опять же все зависит от сложности и детализации объекта.
      </p>
      <p className='lead'>
      Так же для сканирования больших объектов (статуй, построек, памятников и пр.) с целью печати их уменьшенных копий, подойдет такое направление как фотограмметрия. При наличии хорошего оборудования и достаточных навыков можно получить довольно точные модели, которые могут даже в некоторых случаях заменить 3d сканирование, но опять же потребуется гораздо больше сил и времени на получение готовой 3d модели.
      </p>
    </div>

    <div id='solves' className="container col-xxl-10 py-5 solves">
      <FontAwesomeIcon icon={faCamera} size="6x" className='saleIcon' />
      <h2 className="display-5 fw-bold text-center">3D сканирование бесплатно при печати от 250р</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          3D сканирование, это отдельный элемент качественной печати. Мы сделаем его бесплатно для вас.
        </p>
      </div>
    </div>

  </main>
);

export default Scanner;
