import React from 'react';
import '../styles/Slider.scss';

const Slider = ({
                  id = 'carouselSlider', pics = {
    path: '',
    ext: '',
    slides: []
  }
                }) => {

  return (
    <div
      id={id}
      className="carousel carousel-dark slide carouselSlider"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        {
          pics.slides.map((slide, ind) => (
            <button
              key={slide.file}
              type="button"
              data-bs-target={`#${id}`}
              data-bs-slide-to={ind}
              className={!ind ? 'active' : ''}
              aria-current={!ind}
              aria-label={`Slide ${ind + 1}`}
            />
          ))
        }
      </div>
      <div className="carousel-inner">
        {
          pics.slides.map((slide, ind) => (
            <div className={`carousel-item ${!ind ? 'active' : ''}`} data-bs-interval={10000} key={slide.file}>
              <img src={`${pics.path}${slide.file}${pics.ext}`} loading="lazy"
                   className="d-block w-100 bg-gradient bg-secondary bg-opacity-50" alt={slide.file}/>
              <div className="carousel-caption">
                {slide.title ? (<h3>{slide.title}</h3>) : null}
                {slide.subtitle ? (<p>{slide.subtitle}</p>) : null}
              </div>
            </div>
          ))
        }
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target={`#${id}`}
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"/>
        <span className="visually-hidden">Предыдущий</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target={`#${id}`}
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"/>
        <span className="visually-hidden">Следующий</span>
      </button>
    </div>
  );
};

export default Slider;
