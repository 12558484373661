import React from 'react';
import './styles/Layout.scss'

import { Route, Routes } from 'react-router-dom';

import Layout from './Components/Layout';
import Landing from './Pages/Landing';
import Sale from './Pages/Sale';
import Scanner from './Pages/Scanner';
import Materials from './Pages/Materials';
import Resources from './Pages/Resources';
import PageNotFound from './Pages/PageNotFound';
import SmallSerialPrint from './Pages/SmallSerialPrint';
import ModelOptimization from './Pages/ModelOptimization';
import GalleryPage from './Pages/GalaryPage';
import Printer from './Pages/Printer';
import Carbon from './Pages/Carbon';
import SerialPrinting from './Pages/SerialPrinting';


const App = () => ( <
    div className = "App" >
      <Layout >
        <Routes >
          <Route path = "/" element = { < Landing / > }/> 
          <Route path = "/sale" element = { < Sale / > }/>
          <Route path = "/scanner" element = { < Scanner / > }/>
          <Route path = "/carbon" element = { < Carbon / > }/>   
          <Route path = "/materials" element = { < Materials / > }/>
          <Route path = "/printer" element = { < Printer / > }/>
          <Route path = "/resources" element = { < Resources / > }/>
          <Route path = "/smallserialprint" element = { < SmallSerialPrint / > }/>
          <Route path = "/modeloptimization" element = { < ModelOptimization / > }/>
          <Route path = "/gallery" element = { < GalleryPage / > }/>
		  <Route path = "/serialprinting" element = { < SerialPrinting / > }/>
          <Route path='*' element={< PageNotFound /> }/>
        </Routes> 
      </Layout> 
    </div>
);

export default App;