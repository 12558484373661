/**
 * Пути к файлам для главного ////////,
 * названия заголовков и описания к заголовкам
 */
const landingColumnPortfolioData = {
  path: '/img/Landing/portfolio/',
  ext: '.webp',
  slides: [
    {
      file: 'Насадка для кондитерского преса',
      title: 'Насадка для кондитерского преса',
      subtitle: 'шестерня привода заслонки климата Peugeot 407',
      tag: 'инструмент',
    },
    {
      file: 'Форма для изготовления шоколада',
      title: 'Форма для изготовления шоколада',
      subtitle: 'Червячная пара',
      tag: 'инструмент',
    },
    {
      file: 'шестерня привода заслонки климата Peugeot 407',
      title: 'шестерня привода заслонки климата Peugeot 407',
      subtitle: 'это возможность создать объект любой геометрической формы',
      tag: 'авто',
    },
    {
      file: '3dDragon',
      title: '3Д Дракон',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
      tag: 'игрушка',
    },
    {
      file: 'воздухозаборни двигателя',
      title: 'воздухозаборни двигателя',
      tag: 'мото',
    },
    {
      file: 'горлицы из один дома 2',
      title: 'горлицы из один дома 2',
      tag: 'сувенир',
    },    {
      file: 'Детали квадрокоптера',
      title: 'Детали квадрокоптера',
      tag: 'дрон',
    },    {
      file: 'Колпак литого диска BMW',
      title: 'Колпак литого диска BMW',
      tag: 'авто',
    },    {
      file: 'Колпак литого диска Volkswagen',
      title: 'Колпак литого диска Volkswagen',
      tag: 'авто',
    },    {
      file: 'кронштейн крепления бампера jaguar',
      title: 'кронштейн крепления бампера jaguar',
      tag: 'авто',
    },    {
      file: 'кронштейн насоса пневмоподвески TESLA MODEL X',
      title: 'кронштейн насоса пневмоподвески TESLA MODEL X',
      tag: 'авто',
    },    {
      file: 'кронштейн стеклоочистителя',
      title: 'кронштейн стеклоочистителя',
      tag: 'авто',
    },    {
      file: 'Крышка корпуса бензопилы',
      title: 'Крышка корпуса бензопилы',
      tag: 'инструмент',
    },    {
      file: 'Мастер-форма',
      title: 'Мастер-форма',
      tag: 'производство',
    },    {
      file: 'модель люка',
      title: 'модель люка',
      tag: '3д проект',
    },    {
      file: 'молдинг ремня безопасности golf 2',
      title: 'молдинг ремня безопасности golf 2',
      tag: 'авто',
    },    {
      file: 'Мото пластик',
      title: 'Мото пластик',
      tag: 'мото',
    },
  ],
};

export default landingColumnPortfolioData;
