import React from 'react';
import '../styles/Landing.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'

const SerialPrinting = () => (
  <main className='landing-page'>
    <div id='about' className="container col-xxl-10 py-5 about">
      <div className="row flex-lg-row-reverse align-items-center ">
          <img src="/img/Landing/flying-bear-ghost-5.webp" className="d-block col-lg-6 img-fluid"
            alt="3d printer" loading="lazy"
          />
        <div className="col-lg-6">
          <h2 className="display-4 fw-bold lh-1 mb-3">
          Серийная 3D печать
          </h2>
          <a href='#contacts' role='button' className="btn btn-lg px-4 me-md-2 w-100 btn-red-orange">
            Заказать 3D-печать
          </a>
        </div>
      </div>
      <h3 className='display-5 fw-bold lh-1 mb-3 mt-4'>Будущее производства</h3>
      <p className="lead">
      <strong>Серийная 3D печать</strong> - это процесс создания большого количества идентичных изделий с помощью технологии трехмерной печати. Это относительно новый подход к производству, который быстро набирает популярность из-за своих преимуществ перед традиционными методами производства.
      </p>

      <h2>Преимущества серийной 3D печати</h2>
      <img src="/img/Landing/Pages/SerialPrinting/mass-3d-print.webp" className="d-block mx-lg-auto img-fluid"
            alt="serial 3d printing" loading="lazy"
          />
      <p><strong>Сокращения затрат на производство.</strong> Это достигается за счет следующих факторов:</p>
      <ul class="list-group">
        <li class="list-group-item"><strong>Отсутствие необходимости в создании сложных инструментов и матриц:</strong> Традиционные методы производства требуют создания сложных инструментов и матриц, которые могут стоить десятки тысяч долларов. Серийная 3D печать позволяет избежать этих затрат, так как изделия производятся layer-by-layer, без необходимости в создании сложных инструментов.</li>
        <li class="list-group-item"><strong>Уменьшение количества отходов:</strong> 3D печать позволяет производить изделия с минимальным количеством отходов, что уменьшает затраты на материалы и утилизацию отходов.</li>
        <li class="list-group-item"><strong>Сокращение времени производства:</strong> Серийная 3D печать позволяет производить изделия быстрее, чем традиционные методы, что уменьшает затраты на труд и время производства.</li>
        <li class="list-group-item"><strong>Возможность производства малых серий:</strong> Серийная 3D печать позволяет производить малые серии изделий, что уменьшает затраты на хранение и логистику.</li>
        <li class="list-group-item"><strong>Уменьшение затрат на сборку:</strong> 3D печать позволяет производить изделия с уже собранными компонентами, что уменьшает затраты на сборку и монтаж.</li>
        <li class="list-group-item"><strong>Возможность производства изделий с внутренними структурами:</strong> 3D печать позволяет производить изделия с внутренними структурами, которые невозможно произвести традиционными методами, что увеличивает качество и функциональность изделий.</li>
      </ul>

      <p class="mt-4"><strong>Увеличение производительности</strong> является еще одним ключевым преимуществом серийной 3D печати. Это достигается за счет следующих факторов:</p>
      <ul class="list-group mb-4">
        <li class="list-group-item"><strong>Быстрое производство:</strong> 3D печать позволяет производить изделия быстрее, чем традиционные методы, что увеличивает производительность. Это связано с тем, что 3D печать производит изделия layer-by-layer, без необходимости в создании сложных инструментов и матриц.</li>
        <li class="list-group-item"><strong>Автоматизация производства:</strong> Серийная 3D печать позволяет автоматизировать процесс производства, что уменьшает количество ручных операций и увеличивает скорость производства.</li>
        <li class="list-group-item"><strong>Возможность производства круглосуточно:</strong> 3D печать позволяет производить изделия круглосуточно, без необходимости в перерывах на обед и отдых, что увеличивает производительность.</li>
        <li class="list-group-item"><strong>Уменьшение времени на подготовку:</strong> Серийная 3D печать позволяет уменьшить время на подготовку производства, так как не требуется создание сложных инструментов и матриц.</li>
        <li class="list-group-item"><strong>Возможность производства сложных изделий:</strong> 3D печать позволяет производить сложные изделия, которые невозможно произвести традиционными методами, что увеличивает производительность и позволяет производить более сложные изделия.</li>
      </ul>

      <h2>Применение серийной 3D печати</h2>
      <p>Серийная 3D печать нашла широкое применение в различных отраслях и областях, где требуются высокоточные и функциональные изделия. Некоторые из них включают:</p>
      <ul class="list-group">
        <li class="list-group-item"><strong>Аэрокосмическая промышленность:</strong> 3D печать используется для производства сложных компонентов и деталей для самолетов, ракет и спутников.</li>
        <li class="list-group-item"><strong>Медицинская промышленность:</strong> 3D печать используется для производства протезов, имплантатов, моделей органов и тканей для медицинских исследований и лечения.</li>
        <li class="list-group-item"><strong>Автомобильная промышленность:</strong> 3D печать используется для производства автомобильных деталей, таких как двигатели, трансмиссии и другие компоненты.</li>
        <li class="list-group-item"><strong>Промышленное производство:</strong> 3D печать используется для производства сложных компонентов и деталей для различных промышленных машин и оборудования.</li>
        <li class="list-group-item"><strong>Электроника:</strong> 3D печать используется для производства электронных компонентов, таких как печатные платы, антенны и другие устройства.</li>
        <li class="list-group-item"><strong>Обувь и мода:</strong> 3D печать используется для производства сложных моделей обуви, аксессуаров и других изделий моды.</li>
        <li class="list-group-item"><strong>Архитектура и строительство:</strong> 3D печать используется для производства моделей зданий, мостов и других архитектурных сооружений.</li>
        <li class="list-group-item"><strong>Игрушки и развлечения:</strong> 3D печать используется для производства игрушек, фигурок и других изделий для развлечения.</li>
        <li class="list-group-item"><strong>Наука и образование:</strong> 3D печать используется для производства моделей и инструментов для научных исследований и образовательных целей.</li>
      </ul>
    </div>

    <div id='solves' className="container col-xxl-10 py-5 solves">
    <FontAwesomeIcon icon={faHandHoldingDollar} size="6x" className='saleIcon'/>
      <h2 className="display-5 fw-bold text-center">Cкидка на серийную 3D-печати</h2>
      <div className="col-lg-6 mx-auto text-center">
        <p className="lead mb-4">
          При большом колличестве заказов на одинаковую или однотипную печать цены уменьшаются. (обсуждается индивидуально)
        </p>
      </div>
    </div>
  </main>
);

export default SerialPrinting;
