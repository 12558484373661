import React, { useEffect, useRef } from 'react';

/**
 * Компонент кнопка "Вверх"
 * @returns ReactElement
 */
const ButtonUp = () => {
  const UpBtnRef = useRef();

  useEffect(() => {
    /**
     * Контроллер показа/скрытия кнопки. Если прокрутка больше высоты экрана, то кнопка
     * показывается.
     */
    function showUpBtnController() {
      const scrolled = window.pageYOffset < document.documentElement.clientHeight;
      scrolled ? UpBtnRef.current.style.opacity = '0' : UpBtnRef.current.style.opacity = '1';
    }

    window.addEventListener('scroll', showUpBtnController, { passive: true });
  }, []);

  return (
    <button
      ref={UpBtnRef}
      type='button'
      id='buttonUp'
      style={{opacity: 0}}
      className='btn btn-outline-red-orange rounded-circle position-fixed shadow'
      onClick={() => window.scrollTo(0, 0)}
    >
      <svg
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        className="d-block mx-auto"
      >
        <path
          fill="#FF5A3C"
          d="M6.101 261.899L25.9 281.698c4.686 4.686 12.284 4.686 16.971 0L198 126.568V468c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12V126.568l155.13 155.13c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 35.515c-4.686-4.686-12.284-4.686-16.971 0L6.101 244.929c-4.687 4.686-4.687 12.284 0 16.97z"
        />
      </svg>
    </button>
  );
};

export default ButtonUp;
