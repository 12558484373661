/**
 * Пути к файлам для главного слайдера,
 * названия заголовков и описания к заголовкам
 */
const landingHeroSliderData = {
  path: '/img/Landing/hero-slider/',
  ext: '.webp',
  slides: [
    {
      file: 'slide',
      title: '3d моделирование',
      subtitle: '3D печать это изготовление предметов из пластика по ранее созданной цифровой модели',
    },
    {
      file: 'slide_02',
      title: 'Печать изделий на 3D принтере',
      subtitle: 'перспективная и многофункциональная современная технология',
    },
    {
      file: 'slide_03',
      title: '3D-принтер',
      subtitle: 'это возможность создать объект любой геометрической формы',
    },
    {
      file: 'slide_04',
      title: '"Невозможные фигуры"',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_05',
      title: 'Воздухозаборник двигателя',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_06',
      title: 'Гитара гибсон лес пол',
      subtitle: 'это изготовление предметов из пластика по ранее созданной цифровой модели',
    },
    {
      file: 'slide_07',
      title: 'Держатель резца',
      subtitle: 'перспективная и многофункциональная современная технология',
    },
    {
      file: 'slide_08',
      title: 'Деталь газонокосилки',
      subtitle: 'это возможность создать объект любой геометрической формы',
    },
    {
      file: 'slide_09',
      title: 'Заглушка',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_10',
      title: 'Керамбит',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_11',
      title: 'Колпак',
      subtitle: 'это изготовление предметов из пластика по ранее созданной цифровой модели',
    },
    {
      file: 'slide_12',
      title: 'корпус джойстика 3D модель',
      subtitle: 'перспективная и многофункциональная современная технология',
    },
    {
      file: 'slide_13',
      title: 'Корпус фары',
      subtitle: 'это возможность создать объект любой геометрической формы',
    },
    {
      file: 'slide_14',
      title: 'Корпус',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_15',
      title: 'Кронштейн дворников',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_16',
      title: 'Кронштейн компрессора Tesla model X',
      subtitle: 'это изготовление предметов из пластика по ранее созданной цифровой модели',
    },
    {
      file: 'slide_17',
      title: 'Крышка на колпак',
      subtitle: 'перспективная и многофункциональная современная технология',
    },
    {
      file: 'slide_18',
      title: 'Крышка',
      subtitle: 'это возможность создать объект любой геометрической формы',
    },
    {
      file: 'slide_19',
      title: 'Маска',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_20',
      title: 'Модель завода',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_21',
      title: 'Мышь пк',
      subtitle: 'это изготовление предметов из пластика по ранее созданной цифровой модели',
    },
    {
      file: 'slide_22',
      title: 'Наушник 3d модель',
      subtitle: 'перспективная и многофункциональная современная технология',
    },
    {
      file: 'slide_23',
      title: 'Обтекатель квадроцикла',
      subtitle: 'это возможность создать объект любой геометрической формы',
    },
    {
      file: 'slide_24',
      title: 'Панель усилителя',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_25',
      title: 'Пластик авто',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_26',
      title: 'Протез 3d модель',
      subtitle: 'это изготовление предметов из пластика по ранее созданной цифровой модели',
    },
    {
      file: 'slide_27',
      title: 'Прототип корпуса усилителя',
      subtitle: 'перспективная и многофункциональная современная технология',
    },
    {
      file: 'slide_28',
      title: 'Решетка противотуманки',
      subtitle: 'это возможность создать объект любой геометрической формы',
    },
    {
      file: 'slide_29',
      title: 'Рогатка',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_30',
      title: 'Ролик 2',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_31',
      title: 'Ролик',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_32',
      title: 'Tesla вилка',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_33',
      title: 'Толкатель',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_34',
      title: 'Шестерня косозубая',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_35',
      title: 'Шестерня мерседес',
      subtitle: '3D-принтер может создать объект который невозможно выполнить даже на ЧПУ',
    },
    {
      file: 'slide_36',
      title: 'Шестерня',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_37',
      title: '3D модель штекер',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_38',
      title: '3D-печать',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_39',
      title: '3D-печать',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_40',
      title: '3D-печать',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_41',
      title: '3D-печать',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
    {
      file: 'slide_42',
      title: '3D-печать',
      subtitle: 'это практически неограниченные возможности при моделировании изделий',
    },
  ],
};

landingHeroSliderData.slides = landingHeroSliderData.slides.sort(() => 0.5 - Math.random()).slice(0, 5);

export default landingHeroSliderData;
