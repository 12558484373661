import React from 'react';
import '../styles/ErrorNotFound.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'

const PageNotFound = () => (
  <main className='landing-page'>
    <div className='system-information'>
      <h1>404 page not found</h1>
      <h2>Sorry!</h2>
    </div>
  </main>
);

export default PageNotFound;
